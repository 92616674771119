(function ($) {
    let $fn = $(".comp_slider_services");

    if ($fn.length) {
        let $slider_image = $fn.find("[data-slider=\"image\"]"),
            $slider_content = $fn.find("[data-slider=\"content\"]"),
            $sliders = [$slider_image, $slider_content],
            $heading_index=  $fn.find(".elm_section_title [data-index]"),
            $label_left = $fn.find(".part_ui_slider_control [data-label=\"left\"]"),
            $label_right = $fn.find(".part_ui_slider_control [data-label=\"right\"]"),
            labels = $fn.find(".part_ui_slider_control").data("labels").split(";");

        cssLoaded(function () {
            $.each($sliders, function () {
                let $el = $(this);
                $el.flickity({
                    contain: true,
                    draggable: false,
                    accessibility: false,
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: true,
                    adaptiveHeight: true,
                });
            });

            let flkty = $slider_content.data('flickity');

            $fn.find(".part_ui_slider_control").sliderControl($sliders, flkty, true);
            $slider_content.on('select.flickity', function() {
                $heading_index.text(flkty.selectedIndex+1);

                let prevIndex = (flkty.selectedIndex === 0) ? flkty.slides.length - 1 : flkty.selectedIndex - 1;
                let nextIndex = (flkty.selectedIndex === flkty.slides.length - 1) ? 0 : flkty.selectedIndex + 1;

                $label_left.text(labels[prevIndex]);
                $label_right.text(labels[nextIndex]);
            });

            $slider_content.flickity('select', 0);
        });
    }
})(jQuery);