(function ($) {
    let $fn = $(".comp_detail");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]");

        if($slider.children().length > 1){
            cssLoaded(function () {
                $slider.flickity({
                    wrapAround: true,
                    pageDots: false,
                    prevNextButtons: true,
                    contain: true,
                    cellAlign: "left"
                });
            });
        }
    }
})(jQuery);