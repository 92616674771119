(function ($) {
    let $fn = $(".part_ui_booking");

    if ($fn.length) {
        let lang = $("html").attr("lang"),
            today = new Date();

        //Shared datepicker options
        let dp_options = {
            language: lang,
            dateFormat: "d M",
            altFieldDateFormat: "yyyy-mm-dd",
            position: "top left",
            autoClose: true,
            onHide: function($inst){
                $inst.update('position', 'top left'); // Update the position to the default again
            },
            onShow: function($inst, animationComplete){
                // Just before showing the datepicker
                if(!animationComplete){
                    let iFits = false;
                    // Loop through a few possible position and see which one fits
                    $.each(['top left', 'bottom left', 'top right', 'bottom right'], function (i, pos) {
                        if (!iFits) {
                            $inst.update('position', pos);
                            let fits = inViewport($inst.$datepicker[0]);
                            if (fits.all) {
                                iFits = true;
                            }
                        }
                    });
                }
            }
        };

        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+lang+".min.js").done(function () { //Load localization
            $fn.each(function () { //create datepickers on every booking part
                let $form = $(this).find("form"),
                    $dp_in_alt = $form.find("[data-alt=\"checkin\"]"),
                    $dp_out_alt = $form.find("[data-alt=\"checkout\"]");

                let $dp_in = $form.find("[data-datepicker=\"checkin\"]").datepicker($.extend({},dp_options,{
                    altField: $dp_in_alt,
                    minDate: today,
                    onSelect:function (fd,d,$inst) {
                        $dp_out.update({
                            minDate: new Date(d.getTime() + (24*60*60*1000))
                        });
                        setTimeout(function () {
                            if(d > new Date($dp_out['$el'].siblings("input").val())){
                                $dp_out.selectDate(new Date(d.getTime() + (24*60*60*1000)))
                            }
                        },50);
                    }
                })).data('datepicker');

                let $dp_out = $form.find("[data-datepicker=\"checkout\"]").datepicker($.extend({},dp_options,{
                    altField: $dp_out_alt,
                    minDate: new Date(today.getTime() + (24*60*60*1000)),
                    startDate: new Date(today.getTime() + (24*60*60*1000)),
                    onSelect:function (fd,d,$inst) {

                    }
                })).data('datepicker');

                $dp_in.selectDate(today);
                $dp_out.selectDate(new Date(today.getTime() + (24*60*60*1000)))
            });
        });
    }
})(jQuery);