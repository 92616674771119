(function ($) {
    let $fn = $(".comp_slider_rooms");

    if ($fn.length) {
        let $slider_description = $fn.find("[data-slider=\"description\"]"),
            $slider_main_image = $fn.find("[data-slider=\"main_image\"]"),
            $slider_images = $fn.find("[data-slider=\"images\"]"),
            $sliders = [$slider_description, $slider_images, $slider_main_image];

        cssLoaded(function () {
            $.each($sliders, function () {
                let $el = $(this);
                $el.flickity({
                    contain: true,
                    draggable: false,
                    accessibility: false,
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: true,
                    adaptiveHeight: true,
                });
            });

            let flkty = $slider_description.data('flickity');

            $fn.find(".part_ui_slider_control").sliderControl($sliders, flkty, true);
        });
    }
})(jQuery);