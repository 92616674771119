$.fn.sliderLast = function () {
    let $slider = $(this);

    let flkty = $slider.data("flickity");
    let _index = flkty.selectedIndex;
    let _length = flkty.slides.length -1;
    $slider.on("select.flickity",function () {
        let target = flkty.selectedIndex;
        if((_index === _length || _index === 0) && (target === 0 || target === _length)){
            if(target === 0) {
                //right
                $(flkty.cells[_length].element).addClass("mod--last");
            }
            if(target === _length) {
                //left
                $(flkty.cells[0].element).addClass("mod--last");
            }
        }
        else {
            $(flkty.cells[_index].element).addClass("mod--last");
        }
        _index = target;
        setTimeout(function () {
            $slider.find(".mod--last").removeClass("mod--last");
        },500);
    });
};