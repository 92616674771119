(function ($) {
    let $fn = $(".comp_visual");

    if ($fn.length) {
        $fn.on("click","[data-scroll]",function () {
            $fn.next("section").anchor_anim(1500);
        });

        $fn.each(function () {
            let $this = $(this),
                $slider = $this.find("[data-visual]");

            if($slider.length && $slider.children().length > 1) {
                let autoplay = $slider.data("visual");
                cssLoaded(function () {
                    $slider.flickity({
                        contain: true,
                        autoPlay: autoplay,
                        prevNextButtons: false,
                        pageDots: true,
                        wrapAround: true,
                        pauseAutoPlayOnHover: false,
                        setGallerySize: false,
                    })
                })
            }
        })
    }
})(jQuery);