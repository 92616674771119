(function ($) {
    let $fn = $(".comp_slider_about");

    if ($fn.length) {
        let $slider_big = $fn.find('[data-slider=\"big\"]'),
            $slider_small = $fn.find('[data-slider=\"small\"]'),
            $sliders = [$slider_big, $slider_small];

        cssLoaded(function () {
            $.each($sliders, function () {
                let $el = $(this);
                $el.flickity({
                    contain: true,
                    draggable: false,
                    accessibility: false,
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: true,
                });
            });

            let flkty = $slider_big.data('flickity');

            $fn.find(".part_ui_slider_control").sliderControl($sliders, flkty, true);

        });
    }
})(jQuery);