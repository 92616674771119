(function ($) {
    let $fn = $(".comp_slider_offers");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]");

        cssLoaded(function () {
            $slider.flickity({
                draggable: true,
                accessibility: true,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true,
                cellAlign: "left",
            });

            let flkty = $slider.data('flickity');

            $fn.find(".part_ui_slider_control").sliderControl($slider, flkty, true);
        });
    }
})(jQuery);