$.fn.sliderControl = function ($sliders, $inst, wrap = false) {
    let $control = $(this);

    $.each($sliders,function () {
        $(this).sliderLast();
    });

    $control.on("click","[data-arrow]",function (e) {
        e.preventDefault();
        e.stopPropagation();
        let $el = $(this);
        if($el.hasClass("mod--disabled")){
            return;
        }

        $el.addClass("mod--disabled").siblings().addClass("mod--disabled");
        if ($(this).is("[data-arrow=\"next\"]")){
            $.each($sliders, function () {
                $(this).flickity("next");
            });
        }
        if ($(this).is("[data-arrow=\"prev\"]")){
            $.each($sliders, function () {
                $(this).flickity("previous");
            });

        }
        setTimeout(function () {
            $el.removeClass("mod--disabled").siblings().removeClass("mod--disabled");
        },500);
    });

    $inst.on("select",function () {
        changeNav();
    });

    function changeNav() {
        $control.find("[data-index]").text($inst.selectedIndex + 1);

        if(!wrap){
            if($inst.selectedIndex === 0){
                $control.find("[data-arrow=\"prev\"]").addClass("mod--disabled");
            }
            if($inst.selectedIndex === $inst.slides.length -1){
                $control.find("[data-arrow=\"next\"]").addClass("mod--disabled");
            }
        }
    }
};