(function ($) {
    let $fn = $(".comp_video");

    if ($fn.length) {
        let $video = $fn.find("video"),
            $video_elm = $video.get([0]);

        $fn.on("click","[data-overlay]",function () {
            $fn.find("[data-overlay]").fadeOut(500);
            $video.attr("controls","controls");
            $video_elm.play();
        });

        $video_elm.onended = function() {
            $fn.find("[data-overlay]").fadeIn(500);
        };
    }
})(jQuery);