(function ($) {
    let $fn = $(".comp_contact");

    if ($fn.length) {
        const $map = $fn.find("[data-map]");
        if ($map.length) {
            $.getScript(cdnjs.googlemap.replace("APIKEY", $map.data("google-map"))).done(function () {
                $map.nl_google_map('init');
                $map.nl_google_map('markers_add');
            });
        }
    }
})(jQuery);