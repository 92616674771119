(function ($) {
    let $fn = $(".comp_visual_subpage");

    if ($fn.length) {
        let $slider = $fn.find("[data-visual]");

        if ($slider.length && $slider.children().length > 1) {
            let autoplay = $slider.data("visual");
            cssLoaded(function () {
                $slider.flickity({
                    contain: true,
                    autoPlay: autoplay,
                    prevNextButtons: false,
                    pageDots: true,
                    wrapAround: true,
                    pauseAutoPlayOnHover: false,
                    setGallerySize: false,
                })
            })
        }
    }
})(jQuery);